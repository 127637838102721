<template>
  <div class="aside">
    <img style="cursor: pointer;" @click="nav()" src="../assets/images/logo.png"/>

    <div class="list">
<!--      <div class="item title">-->
<!--        <p>ダッシュボード</p>-->
<!--      </div>-->
      <div class="menu">
        <div class="item">
          <div @click="selectIndex(0)" :class="index == 0 ? 'selected' : ''">
            <p>ダッシュボード</p>
          </div>
        </div>
        <div class="item" v-if="USER_INFO.isAdmin">
          <div @click="selectIndex(1)" :class="index == 1 ? 'selected' : ''">
            <p>フロー設計</p>
          </div>
        </div>
        <div  class="item">
          <div @click="selectIndex(2)" :class="index == 2 ? 'selected' : ''">
            <p>フロー運用</p>
          </div>
        </div>
        <div class="item">
          <div @click="selectIndex(3)" :class="index == 3 ? 'selected' : ''">
            <p>説明会管理</p>
          </div>
        </div>
        <div  class="item">
          <div @click="selectIndex(4)" :class="index == 4 ? 'selected' : ''">
            <p>エントリー管理</p>
          </div>
        </div>
        <div  class="item">
          <div @click="selectIndex(13)" :class="index == 13 ? 'selected' : ''">
            <p>特別エントリー管理</p>
          </div>
        </div>

        <div  class="item">
<!--          <div @click="selectIndex(5)" :class="index == 5 ? 'selected' : ''">
            <p>ペルソナ(準備中)</p>
          </div>-->
          <div  :class="index == 5 ? 'selected' : ''">
            <p>ペルソナ(準備中)</p>
          </div>
        </div>
        <div  class="item">
          <div @click="selectIndex(6, 1)" :class="open ? 'selected' : ''"  class="arrow">
            <p>スカウト</p>
            <img src="../assets/images/arrow.png"/>
          </div>
          <div class="sec-menu">
            <p class="sec-item" @click="selectIndex(12)" :class="index == 12 ? 'selected' : ''" >ダッシュボード</p>
            <p class="sec-item" @click="selectIndex(9)" :class="index == 9 ? 'selected' : ''" >送信設定</p>
            <p class="sec-item" @click="selectIndex(10)" :class="index == 10 ? 'selected' : ''" >保存リスト</p>
            <p class="sec-item" @click="selectIndex(11)" :class="index == 11 ? 'selected' : ''" >テンプレート</p>
          </div>
        </div>
        <div class="item" v-if="USER_INFO.isAdmin">
          <div @click="selectIndex(7)" :class="index == 7 ? 'selected' : ''" >
            <p>アカウント</p>
          </div>
        </div>


        <div  class="item">
          <div @click="selectIndex(8)" :class="index == 8 ? 'selected' : ''">
            <p>ヘルプ</p>
          </div>
        </div>

        <a href="https://winc-career.jp" target="_blank">
          <img class="aside-img" src="../assets/images/aside.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminAside",

  data() {
    return {
      index: 0,
      USER_INFO:{},

      open: false
    }
  },



  mounted() {

    // console.log(window.location.href.includes('/explainMeeting'))
    if(localStorage.USER_INFO) {
      this.USER_INFO =JSON.parse(localStorage.USER_INFO);

    }
    let url = window.location.href;
    // console.log(url)
    if(url.includes('/processDesign')) {
      this.index = 1;
    }  else if(url.includes('/processOperation')) {
      this.index = 2;
    } else if(url.includes('/explainMeeting')) {
      this.index = 3;
    } else if(url.includes('/entryLevel')) {
      this.index = 4;
    } else if(url.includes('/persona')) {
      this.index = 5;
    } else if(url.includes('/persona')) {
      // this.index = 6;
    } else if(url.includes('/account')) {
      this.index = 7;
    } else if(url.includes('/help')) {
      this.index = 8;
    } else if(url.includes('/deliverySettings')) {
      this.index = 9;
    } else if(url.includes('/scout')) {
      this.index = 11;
    } else if(url.includes('/saveList')) {
      this.index = 10;
    }  else if(url.includes('/ae')) {
      this.index = 12;
    }
    else {
      this.index = 0;
    }

  },

  methods: {
    nav() {
      this.$router.push('/index')
    },

    selectIndex(index, type) {
     /* if(index == 5 || index == 6) {
        return;
      }*/


      if(index != 8){
        this.index = index;
      }

      if(type != 1) {
        // this.index = index;
        console.log("ssssssssss")
        if(index == 0) {
          this.$router.push('/index');
        } else if (index == 1) {
          this.$router.push('/index/processDesign');
        } else if (index == 2) {
          this.$router.push('/index/processOperation');
        } else if (index == 3) {
          this.$router.push('/index/explainMeeting');
        } else if (index == 4) {
          this.$router.push('/index/entryLevel');
        } else if (index == 5) {
          // return;
          this.$router.push('/index/persona');
        } else if (index == 6) {
          // this.$router.push('/index/processDesign');
        } else if (index == 7) {
          this.$router.push('/index/account');
        } else if (index == 8) {
          window.open('https://p-partners.tayori.com/q/winc-client/');
        } else if (index == 9) {
          this.$router.push('/index/deliverySettings');
        } else if (index == 11) {
          this.$router.push('/index/scout');
        } else if (index == 12) {
          this.$router.push('/index/ae');
        } else if (index == 10) {
          this.$router.push('/index/saveList');
        } else if (index == 13) {
          this.$router.push('/index/specialEntry');
        }

      } else {
        this.open = !this.open;
        console.log(this.index)
      }

      // if(this.index == index && type == 1) {
      //   this.index = -1;
      // } else {
      //   this.index = index;
      // }
    },
  }
}
</script>

<style scoped>
.aside-img {
  width: 12.5rem;
  margin: 1.953rem auto 0;
  display: block;
  cursor: pointer;
}

  .aside {
    width: 100%;
    height: 100%;
    /*height: calc(100vh - 2.6rem);*/
    overflow: auto;
    background-color: rgb(255,255,255);
    border: 1px transparent solid;
  }

  .aside>img {
    width: 10.4rem;
    display: block;
    margin: 1.4rem auto 2.8rem;
  }

  .list .item p {
    text-align: center;
    font-size: .9rem;
    color: #424242;
    padding: .6rem 0;

    font-style: normal;
    border-bottom: 0.5px solid #A2A2A2;
    cursor: pointer;
    /*position: relative;*/
    transition: .3s;
  }

  .list .menu {
    padding-bottom: 4rem;
  }

  .list .menu .item>div:nth-child(1) {
    transition: .3s;
  }

  .list .menu .item>div:nth-child(1):hover {
    background: #EEEEEE;
    box-shadow: inset 1.25px 1.25px 1.5px rgba(0, 0, 0, 0.2);
  }

  .list .menu .item>div:nth-child(1).selected p {
    background: #EEEEEE;
    box-shadow: inset 1.25px 1.25px 1.5px rgba(0, 0, 0, 0.2);
  }

  .list .menu .item .arrow img {
    width: 1rem;
    position: absolute;
    top: .9rem;
    right: 1.5rem;
    transform: rotate(180deg);
    transition: .3s;
  }

  .list .menu .item {
    position: relative;
  }

  .list .menu .item .arrow.selected {
    background: #EEEEEE;
    box-shadow: inset 1.25px 1.25px 1.5px rgba(0, 0, 0, 0.2);
  }

  .list .menu .item .arrow.selected img {
    transform: rotate(0);
  }

  .list .title {
    cursor: auto;
  }

  .list .menu .item .sec-menu {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);

    height: 0;
    overflow: hidden;
  }

  .list .menu .item .sec-menu p {
    /*height: 0;*/
    /*padding: 0;*/
    /*overflow: hidden;*/
    /*transition: .3s;*/
  }

  list .menu .item .arrow.selected + .sec-menu>.sec-item {
    /*height: 100px;*/
    /*color: rebeccapurple;*/
    /*line-height: 100px;*/
  }

  .list .menu .item .arrow.selected + .sec-menu {
    /*height: 100px;*/
    height: auto;
  }

  .list .menu .item .sec-menu .sec-item {
    border-bottom: none;
    transition: .3s;
  }

  .list .menu .item .sec-menu .sec-item:hover {
    background: #EEEEEE;
  }
  .list .menu .item .sec-menu .sec-item.selected {
    background: #EEEEEE;
  }


</style>
