<template>
  <div class="index">
    <el-container class="index">

      <el-container>
<!--        <el-aside width="18.8rem; overflow: hidden;">-->
        <el-aside class="aside" :class="open ? '' : 'close'">
          <admin-aside :class="open ? '' : 'close'" style="position: fixed; z-index: 9" ref="aside"></admin-aside>
        </el-aside>
        <el-container>
          <el-header height="auto">
            <admin-header style="position: fixed" @changeMenu="changeMenu"></admin-header>
          </el-header>
          <el-main>
            <router-view></router-view>
<!--            <ae></ae>-->
          </el-main>

        </el-container>
      </el-container>
      <el-footer style="z-index: 9" height="auto"><admin-footer></admin-footer></el-footer>

<!--      <div style="height: 2.4rem"></div>-->
    </el-container>


  </div>
</template>

<script>
import adminAside from '@/components/adminAside.vue'
import adminFooter from '@/components/adminFooter.vue'
import adminHeader from '@/components/adminHeader.vue'
export default {
  name: "index",

  components: {
    adminFooter,
    adminAside,
    adminHeader
  },

  data() {
    return {
      open: true,
    }
  },

  mounted() {
    // this.$router.push('test')


  },

  methods: {


    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    changeMenu() {
      this.open = !this.open;
    }
  }
}
</script>

<style scoped>
  .el-header {
    height: 4.1rem !important;
    position: relative;
    z-index: 999;
  }


  .index {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgb(235,241,242);
  }

  .el-main,.el-header , .el-footer {
    padding: 0;
  }

  .aside {
    transition: all ease .3s;
    width: 14.7rem !important;
    overflow: hidden;
    border: 1px rgb(163,163,163) solid;
    box-sizing: border-box;
  }

  .aside.close {
    width: 0 !important;
  }

  .el-header, .el-footer {
    /*background-color: #B3C0D1;*/
    /*color: #333;*/
    /*text-align: center;*/
    /*line-height: 60px;*/
  }

  .el-aside {
    /*background-color: #D3DCE6;*/
    /*color: #333;*/
    /*text-align: center;*/
    /*line-height: 200px;*/
  }

  .el-main {
    /*background-color: #E9EEF3;*/
    /*color: #333;*/
    /*text-align: center;*/
    /*line-height: 160px;*/
  }

  body > .el-container {
    /*margin-bottom: 40px;*/
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    /*line-height: 260px;*/
  }

  .el-container:nth-child(7) .el-aside {
    /*line-height: 320px;*/
  }




</style>
