<template>
  <div class="container" :class="open ? '' : 'close'">
    <div class="top">
      <div>
        <img class="user hover-no" src="../assets/images/user.png">
        <img class="user hover" src="../assets/images/index/user_w.png">
        <span>{{userInfo.username}}</span>
        <img class="arrow hover-no" src="../assets/images/arrow_down.png">
        <img class="arrow hover" src="../assets/images/index/arrow_down_w.png">

        <div class="head-menu">
          <div class="name">{{userInfo.username}}</div>

          <div class="head-bottom">
            <div @click="clickTab(1)">ユーザー詳細</div>
            <div @click="clickTab(2)">ログアウト</div>
          </div>

          <img src="../assets/images/index/arrow_top_w.png"/>
        </div>
      </div>
    </div>
    <img @click="changeMenu" class="menu" src="../assets/images/menu.png">

  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "adminHeader",

  data() {
    return {
      userInfo: {},

      open: true

    }
  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
  },

  methods: {
    changeMenu() {
      this.open = !this.open;
      this.$emit('changeMenu')
    },

    clickTab(type) {
      if(type == 2) {
         localStorage.removeItem("USER_INFO");
         localStorage.removeItem("RPA_TOKEN");
        this.$router.push('/login');
      } else if (type == 1) {
        //window.location.href = '/#/index/account?type=1';
         this.$router.push('/index/accountForm?type=1');
      }
    }
  }

}
</script>

<style scoped>
  .container {
    width: calc(100% - 14.7rem);
    /*height: 10.7rem;*/
    position: relative;
    transition: .3s;
    background-color: white;
  }

  .container.close {
    width: 100%;
  }

  .top, .bottom {
    height: 4.1rem;
    border-bottom: 0.5px solid #A2A2A2;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;

  }

  .top>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: .3s;
    position: relative;


  }

  .top>div:hover {
    background: #424242;
  }

  .top>div:hover .hover {
    display: inline-block;
  }

  .top>div .hover {
    display: none;
  }

  .top>div .hover-no {
    display: inline-block;
  }

  .top>div:hover .hover-no {
    display: none;
  }

  .top>div:hover .head-menu {
    visibility: visible;
    opacity: 1;
  }

  .top>div:hover span {
    color: white;
  }

  .top .user {
    width: 1rem;
    margin-right: .546rem;
    margin-left: 1.638rem;
  }

  .top .arrow {
    width: 1rem;
    margin-right: 1.638rem;
  }

  .top span {
    font-size: .7rem;

    font-style: normal;
    font-weight: 400;
    margin-right: .456rem;
    color: #424242;
  }

  .menu {
    width: 3.1rem;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  .bottom {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    /* identical to box height */
    color: #424242;
    border-top: 1px transparent solid;
  }

  .bottom p {
    margin: 1.3rem 0 0 3.1rem;
  }

  .head-menu {
    position: absolute;
    right: .1rem;
    top: 99%;

    width: 14.82rem;
    /*height: 6.24rem;*/

    background: #FFFFFF;
    /*border: 0.039rem solid #A2A2A2;*/
    box-shadow: 0px .312rem .312rem rgba(0, 0, 0, 0.07);

    transition: .3s;
    visibility: hidden;
    opacity: 0;
  }

  .head-menu img {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1.524rem;
    top: -.9rem;
  }

  .head-menu .name {
    width: 100%;
    height: 2.34rem;
    line-height: 2.34rem;
    background: #202020;
    border: 0.078rem solid #FFFFFF;


    font-style: normal;
    font-weight: 400;
    font-size: .936rem;
    text-align: center;

    color: #FFFFFF;
  }

  .head-menu .head-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.106rem;
    width: 100%;

    /*height: 100%;*/
  }

  .head-menu .head-bottom div:hover {
    background: #BBBBBB;
    color: #fff;
  }

  .head-menu .head-bottom div {
    width: 6.24rem;
    height: 2.574rem;

    background: #FFFFFF;
    border: 0.039rem solid #A1A1A1;
    transition: .3s;

    font-style: normal;
    font-weight: 600;
    font-size: .78rem;
    line-height: 2.574rem;
    text-align: center;

    color: rgba(32, 32, 32, 0.75);

    cursor: pointer;
  }

  .head-menu .head-bottom div:nth-child(1) {
    margin-right: .78rem;
  }

</style>
